import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import CottageAtKaund from '../components/Locations/CottageAtKaund'
const Kaund = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Cottage At Kaund"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Cottage@Kaund"
            />
            <CottageAtKaund />
            <Footer />
        </Layout>
    );
}

export default Kaund