import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'

const CottageAtKaund = () => {
    return (
        <section className="about-area ptb-10">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" />
                                    Cottage @ Kaund
                                </span>
                                <h2>Charming and Idyllic Retreat</h2>
                                <p> The cottage nestled in the green hills is a charming and idyllic retreat. Surrounded by lush foliage, rolling hills,
                                    and fresh mountain air, it's a perfect escape from the hustle and bustle of city life.The cottage at Kaund offers
                                    a unique opportunity to connect with nature and explore the great outdoors.</p>
                                <p> You can be able to go on scenic hikes through the Shivalik Range,
                                    take leisurely walks through the countryside, or even go horseback riding. The natural beauty of the surrounding landscape can
                                    be truly awe-inspiring, with breathtaking vistas, stunning sunrise and mesmerising  sunsets that are sure to take your breath away.</p>

                                <p> Inside the cottage, you'll find a cozy and comfortable atmosphere that's perfect for relaxing and unwinding.
                                    The cottage is equipped with amenities such as a private park and a cozy sitting area, where you can curl up
                                    with a good book and enjoy the peaceful surroundings.
                                    With no distractions, you can truly take time to focus on yourself and recharge your batteries.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <p> One of the best things about at cottage at kaund is the opportunity to disconnect from
                                        technology and the stresses of modern life. Without the constant notifications and distractions
                                        from your phone or computer, you can truly disconnect and be present in the moment.
                                        This can help you feel more grounded and centered, and can be a powerful way to reduce stress and
                                        improve your overall well-being.</p>
                                    <p> Overall, a cottage at green hills can be a truly magical and rejuvenating experience.
                                        With its natural beauty, tranquil surroundings, and opportunities for adventure and relaxation,
                                        it's a wonderful way to escape the stresses of everyday life and find peace in the beauty of the world around us.  </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default CottageAtKaund;